app-activity-editor-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
}

app-activity-edition-preview {
    order: 0;
    flex: 40 1 auto;
    align-self: auto;
}

app-activity-edition-form {
    order: 0;
    flex: 60 1 auto;
    align-self: auto;
}