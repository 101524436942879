@use 'sass:list';
@use 'sass:string';

@import "../components/app-keywords";
@import "../pages/app-compass";

// --------------------------------------------------
// keywords component
// --------------------------------------------------
.keywords {
    display: flex;
    padding: 3px 14px;
    justify-content: center;
    align-items: center;
    // Style
    border-radius: $radius-rounded;
    background: lighten(desaturate(adjust-hue(#E8EDF6, 1), 20.67), 1.18);
    color: #333A49;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

}

// --------------------------------------------------
// compass theme system
// --------------------------------------------------
$compasNavButtonColorSet: (
    "#9342D2",
    "#5BC0EB",
    "#F2545B",
    "#5E2BFF",
    "#FE9920",
    "#dd54f2",
);
// public newsCardClasses: string[] = ["map-theme1", "map-theme2", "map-theme3", "map-theme4", "map-theme5", "map-theme6"]];
.map-theme {
    position: relative;
    background-color: white;
}
$compasThemeHeaderColorSet: (
    diagnostics: (
        background-color: #9342D2,
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-01.svg)
    ),
    skills: (
        background-color: #5BC0EB,
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-02.svg)
    ),
    difficulty: (
        background-color: #F2545B,
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-03.svg)
    ),
    peda: (
        background-color: #FE9920,
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-04.svg)
    ),
    orga: (
        background-color: #5E2BFF,
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-05.svg)
    )
);

.compass-themes__header {
    @include classesGenerator('&.map-theme-', $compasThemeHeaderColorSet);
}
.map-theme0 {
    &::after {
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-01.svg);
    }
    &::before {
        background-color: #{ list.nth($compasNavButtonColorSet, 1) };
    }
}
.map-theme1 {
    &::after {
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-02.svg);
    }
    &::before {
        background-color: #{ list.nth($compasNavButtonColorSet, 2) };
    }
}
.map-theme2 {
    &::after {
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-03.svg);
    }
    &::before {
        background-color: #{ list.nth($compasNavButtonColorSet, 3) };
    }
}
.map-theme3 {
    &::after {
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-04.svg);
    }
    &::before {
        background-color: #{ list.nth($compasNavButtonColorSet, 4) };
    }
}
.map-theme4 {
    &::after {
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-05.svg);
    }
    &::before {
        background-color: #{ list.nth($compasNavButtonColorSet, 5) };
    }
}
.map-theme5 {
    &::after {
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-06.svg);
    }
    &::before {
        background-color: #{ list.nth($compasNavButtonColorSet, 6) };
    }
}

// --------------------------------------------------
// compass card generic
// --------------------------------------------------
app-compass-card {
    display: flex;
    flex-direction: column;
    gap: 14px;
    // Style
    border-radius: $radius-3;
    overflow: hidden;
    border: none;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.05);
    background: #FDFEFE;
    position: relative;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;

    button {
        @include button($type: 'dark', $icon-on-Right: true);
        margin: 0;
    }

    .app-compass-card__header,
    .app-compass-card__body,
    .app-compass-card__footer {
        padding-inline: 21px;
        z-index: 3;
    }

    .app-compass-card__header {
        padding-top: 18px;
        // box-shadow: inset 2px 2px 4px rgba(255, 183, 0, 0.89);
        h3 {
            margin: 0;
        }
    }

    .app-compass-card__body {
        flex: 1;
        max-width: 65%;
        padding-bottom: 0.9rem;

        h3 {
            margin-top: 0.5rem;
            margin-bottom: 1.2rem;
        }
        p {
            margin: 0;
        }
    }

    .app-compass-card__footer {
        max-width: 75%;
        padding-bottom: 23px;
    }

}

.map-ressource, .compass-card__ressource_infos {
    border-radius: $radius-2;

    .app-compass-card__header {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 12px 6px 6px 6px;
        border-radius: 12px 12px 0 0;
        position: relative;
        h3 {
            color: white;
        }
        &::before {
            position: relative;
            content: '';
            display: block;
            inset: 0;
            width: 24px;
            height: 24px;
        }
    }
    .app-compass-card__body {
        h4 {
            margin: 0;
        }
        p {
            margin-bottom: 10px;
        }
    }

}

.map-ressource-kit_pedagogique {
    .app-compass-card__header {
        color: white;
        background: map-get($compassColorsSecondary, kit_pedagogique);

        &::before {
            background: transparent icon("book_2.svg") no-repeat center center;
        }
    }
    strong, .app-compass-card__footer__media {
        color: map-get($compassColorsSecondary, kit_pedagogique);
    }
}
.map-ressource-parcours_pedagogique {
    .app-compass-card__header {
        color: white;
        background: map-get($compassColorsSecondary, parcours-pedagogique);

        &::before {
            background: transparent icon("conversion_path_compass.svg") no-repeat center center;
        }
    }
    strong, .app-compass-card__footer__media {
        color: map-get($compassColorsSecondary, parcours-pedagogique);
    }
}
.map-ressource-collection {
    .app-compass-card__header {
        color: white;
        background: map-get($compassColorsSecondary, collection);

        &::before {
            background: transparent icon("view_carousel.svg") no-repeat center center;
        }
    }
    strong, .app-compass-card__footer__media {
        color: map-get($compassColorsSecondary, collection);
    }
}
.map-ressource-conseils_pratiques {
    .app-compass-card__header {
        color: white;
        background: #db8637;
        background: map-get($compassColorsSecondary, conseils_pratiques);

        &::before {
            background: transparent icon("footprint.svg") no-repeat center center;
        }
    }
    strong, .app-compass-card__footer__media {
        color: map-get($compassColorsSecondary, conseils_pratiques);
    }
}
.map-ressource-parcours_dautoformation {
    .app-compass-card__header {
        color: white;
        background: map-get($compassColorsSecondary, parcours_dautoformation);

        &::before {
            background: transparent icon("book.svg") no-repeat center center;
        }
    }
    strong, .app-compass-card__footer__media {
        color: map-get($compassColorsSecondary, parcours_dautoformation);
    }
}
.map-ressource-propos_denseignants {
    .app-compass-card__header {
        color: white;
        background: map-get($compassColorsSecondary, propos_denseignants);

        &::before {
            background: transparent icon("book.svg") no-repeat center center;
        }
    }
    strong, .app-compass-card__footer__media {
        color: map-get($compassColorsSecondary, propos_denseignants);
    }
}

.map-ressource-conseils_dexperts {
    .app-compass-card__header {
        color: white;
        background: map-get($compassColorsSecondary, conseils_dexperts);

        &::before {
            background: transparent icon("book.svg") no-repeat center center;
        }
    }
    strong, .app-compass-card__footer__media {
        color: map-get($compassColorsSecondary, conseils_dexperts);
    }
}



// https://sass-lang.com/playground/#eJyVVG1v2jAQ/p5fcYJogBQoS0k1glpFMNiXqV/6AyrjGGqR2NQvW6Uq/32OnaQpkLH5S3J3j5+7e87212miJYGeRFLGUgnK9r2F1/LlRCHj8XxBJNcCE7lCIl1lJkZkDEMPzMrRcXygqjbLhXnGRQy/X6giQePdInzYC65ZGkM/Qnd3uBWkmLMYelvODz3rHAUN+xEJbNLL/0xBwmj37UqK0cLzMc+PSD6iX0utFGerkvmJNA3157ez8HvoePrRcjVdLytjE0azqDaidbjcbOrIej4Pp5WRptFsF9pknpfk9I0ywE7EH4QRgRQXQ98N4CeVKgD/KMiOvsXAdJaN4N3yJAThF/BNwzkYhtaGClCumxsLiMFFJ5q9aq7I0HpNATUuobs6C9zfw6DUedDicVyQpGSr99B/r7CF+SuZisUn5JfJOeKErDU0G1+cRT+GN+4GFo1VGEEyc1TbaTrLN2qMZUYxGV7UJYBw9NeWPvbbDRZ/ocFTVfGZpg3SVTawncIDDIIuWa4LVytxkny7v5r9VPKrhfzTjGxNXVPzPPcxPxN38+QYm1fl+dk8MtUrY6tOKMOZTsmFq9JxYwPXc3XKy/F3U1x60czG4g9EmlgN
// resourcesCardClasses: string[] = ["map-kit", "map-parcours", "map-collection", "map-conseils", "map-trucs"];
//.compass-card__ressources {
//    @include classesGenerator('map-', $resourcesCardClasses );
//}

app-compass-resources .app-compass-card__footer {
    max-width: unset;
    display: flex;
    justify-content: space-between;
}

.compass-card__ressource_infos {
    .app-compass-card__header {
        min-height: 110px;
        display: flex;
        padding: 34px 19px 24px 19px;
        gap: 3px;

        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        &::before {
            width: 58px;
            height: 58px;
            background-size: contain;
        }

        .compass-card__ressource_infos__breadcrumb {

            font-size: 12px;
            font-style: normal;
            font-weight: 400;
        }
        h2 {
            margin: 0;
            color: white;
        }
    }
    .app-compass-card__body {
        max-width: unset;
        padding-top: 30px;
    }
    &.map-ressource0, &.map-ressource1, &.map-ressource2, &.map-ressource3, &.map-ressource4 {
        .app-compass-card__header::before {
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            left: unset;
            width: 58px;
            height: 58px;
            background-size: 100%;
            position: absolute;
        }
    }
    .app-compass-card__body {
        p {
            color: #333A49;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%; /* 17.6px */
        }
    }

}