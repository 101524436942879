@use '@angular/material' as mat;
@use "sass:map";
@import 'button';



@mixin chips (
    $type: primary,
    $size: m,
) {
    $chip-map: map.get($chip-map, $type);
    $button-map: map.get($button-type, $type);

    // Style
    background: map.get($button-map, background);
    color: map.get($button-map, color) !important;

    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: inline-flex;
    padding: 7px 12px;
    border-radius: 16px;
    align-items: center;
    cursor: default;
    min-height: 32px;
    gap: 10px;

    .mat-chip.mat-standard-chip .mat-chip-remove {
        opacity: 1 !important;
        background: none;
    }

    .mat-chip-remove {
        opacity: 1 !important;
        background: none;
        padding: 0 !important;
        margin: 0 !important;
    }

    .mat-icon {
        background-color: transparent;
        opacity: 1;
        color: map.get($button-map, color);
        svg {
            width: inherit;
            height: inherit;
            min-height: inherit;
            min-width: inherit;
            transform: none;
        }
    }

    a {
        color: inherit;
    }
}