@use '@angular/material' as mat;

%mat-dialog-container {
    background-color: map_get($mat-white, 500);
    color: map_get($mat-black, 500);
    border: none;
    overflow: hidden;


    > *:not(router-outlet) { // composants
        flex: 1 1 0%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        position: relative;
    }


    video, .videoWrapper {}

    .mat-dialog-content {

        div p a {
            color: $dialog__link--color;
            text-decoration: $dialog__link--text-decoration;
        }

        button.submit-button[disabled] {
            color: $dialog__submit-button--disabled--color;
        }
    }
}

.mat-dialog-title,
mat-dialog-container.mat-dialog-container .mat-dialog-title,
.activities-dialog-title {
    flex: none;
    background: $dialog__title--background-color;
    color: $dialog__title--color;
    min-height: 44px;
    padding: 10px 14px 10px 24px;

    h1, h2, h3, .h1, .h2, .h3 {
        font-weight: bold;
        + button.close {
            margin-left: auto;
        }
    }

    button.close {
        @include button("primary", $icon-full: true );
    }

    svg, button.mat-mini-fab.mat-accent svg {
        path {

        }
    }

}

mat-dialog-container.mat-dialog-container {

    .mat-dialog-actions {

        button {
            @extend %button--base;
            @extend %button--accent;
            padding: 8px 16px;
            line-height: 1.4;
        }
    }
}

// Generic dialog modale
mat-dialog-container.mat-dialog-container {
    @extend %mat-dialog-container;
    //@extend %mat-dialog-fields;
}

app-edit-lesson-dialog {
    .mat-form-field-infix mat-select-value {
        min-height: 44px;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #000000;
    }
}

.activities-list-dialog .mat-dialog-container {
    height: 95%;
    margin-top: 1%;
    padding: 0;

    .activities-dialog-title button {
        height: 24px;
        margin-left: auto;
        width: 24px;
    }

    .mat-dialog-content {
        flex-direction: row;
    }

    .multi-content {
        flex: 1;

        .mat-card.activities-card {
            .mat-card-header {
                display: none;
            }

            .mat-card-content {
                flex: 1;
                height: 100%;
            }
        }
    }

    .preview-wrapper, .next-wrapper {
        min-width: 60px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: stretch;
        flex: none !important;

        .preview, .next {
            padding: 0;
            margin: 0;
            width: 32px;
            height: 32px;
            background-color: mat.get-color-from-palette($accent);

            &[disabled] {
                display: none;
            }
        }
    }

}

.activities-list-dialog-preview {
    mat-card-footer {
        display: none !important; // in preview mode we not show action button
    }
}

.fullsizeImage {
    .mat-dialog-container {
        width: 80% !important;
        margin: auto !important;
        height: auto;
    }

    .mat-dialog-content {
        padding: 0 15px 15px 15px !important;
    }

    .consigne-img {
        width: 100%;

        .is-picture {
            height: 100% !important;
        }
    }
}

app-button-list-dialog {

    .mat-dialog-title {
        margin: 0px !important; // il y a un margin-bottom + padding dans le mat-dialog-content, il faut en retirer un des deux
    }

    .mat-dialog-content {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
        gap: 10px;

        button {
            margin-left: 0 !important; // tout les boutons sauf le premier on un margin-left ?!
        }
    }
}

%drop-zone-style {
    border-radius: 0 !important;
    border: 2px dashed $default-font-color !important;
    margin: 15px 0 !important;
    padding: 5px 10px;
    min-height: 120px;
}

%drop-content-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ngx-file-drop__drop-zone-label {
        @extend %corpus-text-style;
        color: $default-font-color;
    }

    .btn {
        @extend %button--accent;
        padding: 10px;
        margin-top: 10px;
    }
}

.corpus-upload {
    %corpus-text-style {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
    }

    .text-center {
        text-align: left;

        span {
            @extend %corpus-text-style;
            color: $default-font-color;
        }

        em {
            @extend %corpus-text-style;
            font-weight: 400;
            color: $default-font-color;
        }

        .ngx-file-drop__drop-zone {
            @extend %drop-zone-style;
        }

        .ngx-file-drop__content {
            @extend %drop-content-style
        }
    }

    .content-url-import {
        @extend %corpus-text-style;
        color: $default-font-color;

        mat-form-field {
            justify-content: center;
            display: flex;
        }

        .mat-form-field-wrapper {
            width: 60%;

            input {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: $default-font-color;
            }
        }
    }
}

app-resource-upload-modal-step2 {
    .ngx-file-drop__drop-zone {
        @extend %drop-zone-style;
    }

    .ngx-file-drop__content {
        @extend %drop-content-style
    }

    .gx-file-drop__drop-zone-label {
        @extend %corpus-text-style;
    }

    .mat-dialog-content .content .content {
        padding: 20px 0 !important;

        :nth-child(3) {
            @extend %corpus-text-style;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 14px;
        }

        :nth-child(4) {
            @extend %corpus-text-style;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
        }

        :nth-child(5) {
            @extend %corpus-text-style;
            font-weight: 400;
        }
    }

    .mat-dialog-content fieldset legend, .mat-dialog-content div p {
        @extend %corpus-text-style;
        font-weight: 400;
    }

    .mat-form-field-label-wrapper label span {
        @extend %corpus-text-style;
        font-size: 12px;
        text-transform: uppercase;
        color: $default-font-color;
    }

    input {
        @extend %corpus-text-style;
        font-weight: 400;
    }
}
