app-grid {
    @include grid($xs-col: 2, $sm-col: 3, $lg-col: 5, $xl-col: 7, $gap: 15);

    padding: 15px 16px;

    border-radius: var(--Radius-L, 10px);
    background: var(--noir-5, rgba(0, 0, 0, 0.05));

    .editor-panel__add-activities {
        display: flex;
        flex-direction: column;
        gap: 20px;
        aspect-ratio: 8/12;

        button {
            flex: 1;

            gap: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;


            border-radius: 7px;
            border: 1px solid var(--noir-10, rgba(0, 0, 0, 0.10));

            /* ombre_bouton */
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
            &:hover {
                box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
            }

            span {
                @include h4-font;
            }

            mat-icon.mat-icon {
                flex: none;
                width: 30%;
                height: auto;
                aspect-ratio: 1/1;
                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .editor-panel__add-activities__button-create,
        .editor-panel__add-activities__button-create:active {
            flex: 2;
            color: $white-c;
            background: $accent-c;

            &:hover {
                background: lighten($accent-c, 10%);
            }
        }
        .editor-panel__add-activities__button-import,
        .editor-panel__add-activities__button-import:active {
            flex: 1;
            color: $accent-c;
            background: $white-c;
            margin: 0;

            &:hover {
                background: lighten($accent-c, 10%);
                color: $white-c;
            }
        }

    }
}