@use '@angular/material' as mat;
@use "sass:map";
@import 'button';

@mixin has-a-counter() {
    &::before {
        position: absolute;
        content: " ";
        width: 16px;
        height: 16px;
        top: calc(50% - 2px);
        bottom: inherit;
        transform: translateY(-50%);
        left: 7px;
        background-color: $accent-c;
        margin: 0;
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px;
    }
    &::after {
        position: absolute;
        content: " " ;
        font-size: 10px;
        line-height: 10px;
        color: white;
        width: 100%;
        height: 3px;
        display: block;
        top: inherit;
        bottom: 0;
        left: 0;
        background-color: $accent-c;
        margin: 0;
        border-radius: 0;
    }
    &:nth-child(1)::before { content: "1"; }
    &:nth-child(2)::before { content: "2"; }
    &:nth-child(3)::before { content: "3"; }
    &:nth-child(4)::before { content: "4"; }
}


@mixin tabs (
    $type: primary,
    $size: m,
    $icon-on-Right: false,
    $counter: false,
) {
    $tab-map: map.get($tab-map, $type);
    $button-map: map.get($button-type, $type);
    .mat-tab-label {
        flex: 1;
        display: flex;
        align-items: center;
        height: auto;
        position: relative;
        min-width: inherit;
        padding: 4px 7px 5px 20px !important;

        background: map.get($tab-map, background);
        color: map.get($tab-map, color) !important;
        // border-radius: map.get($button-map, border-radius);
        border-radius: map-deep-get($tab-map, border-radius, $size);
        overflow: hidden;
        text-align: left;
        //box-shadow: map.get($button-map, box-shadow);
        font: map.get($tab-map, font) !important;

        .mat-tab-label-content {
            padding-bottom: 0;
        }


        @if $counter {
            counter-increment: count;
            @include has-a-counter();
        }
        &.mat-tab-label-active {
            opacity: 1;
        }
    }


    @if map.get($tab-map, mat-ink-bar) == false {
        .mat-ink-bar {
            display: none;
        }
    }



}