/*  xs: 0, sm: 600px, md: 960px, lg: 1280px, xl: 1920px  */

@mixin grid(
    $xs-col: 1,
    $xs-row: 1,
    $sm-col: 3,
    $sm-row: 3,
    $lg-col: 3,
    $lg-row: 3,
    $xl-col: 4,
    $xl-row: 3,
    $gap: null,
    $column-gap: null,
    $row-gap: null,
    $row-height-auto: false,
    ) {
    display: grid;
    @if($gap != null) { grid-gap: $gap + px; }
    @if($column-gap != null) { grid-column-gap: $column-gap + px; }
    @if($row-gap != null) { grid-row-gap: $row-gap + px; }

    grid-auto-rows: min-content;
    grid-template-columns: repeat($xs-col, minmax(0, 1fr)); // toujours même largeur

    @include media-breakpoint-up("sm") { // > 600px
        grid-template-columns: repeat($sm-col, minmax(0, 1fr)); // toujours même largeur
        grid-template-rows: repeat($sm-row, minmax(0, 1fr)); // toujours même largeur
    }

    @include media-breakpoint-up("lg") { // > 1280
        grid-template-columns: repeat($lg-col, minmax(0, 1fr)); // toujours même largeur
        grid-template-rows: repeat($lg-row, minmax(0, 1fr)); // toujours même largeur
    }
    @include media-breakpoint-up("xl") { // > 1920
        grid-template-columns: repeat($xl-col, minmax(0, 1fr)); // toujours même largeur
        grid-template-rows: repeat($xl-row, minmax(0, 1fr)); // toujours même largeur
    }
    @if($row-height-auto == true) { grid-template-rows: min-content !important; }
}


//
/*                     V      */
/*______ V ______|_____V______*/
/*______ V ______|_____V______*/
/*______ V ______|____________*/
@mixin grid-like-flux-2-col {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-between;

    > * {
        width:49%; // Double column
        padding: 0 0 30px 0;
        position:relative;
    }
}