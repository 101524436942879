app-keywords {
    display: flex;
    gap: 6px;
    .keywords {
        flex:0;
        padding: 3px 14px;
        justify-content: center;
        align-items: center;
        // Style
        border-radius: 1em;
        background: lighten(desaturate(adjust-hue(#E8EDF6, 1), 20.67), 1.18);
        text-wrap: nowrap;
    }

}