
app-gamecode-list {

    mat-spinner {
        margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the page
    }

    .gamecode-card-list {
        flex-direction: row;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        max-height: 100%;
        place-content: flex-start;
        align-items: flex-start;

        .mat-card .mat-card-content, .mat-card .mat-card-actions {
            margin-left: 244px;
        }

        .mat-card .mat-card-content {
            flex: 1;

            .dl-listitem-inline dt, .dl-listitem-inline dd {
                display: inline;
                max-width: 70%;
            }

            img.mat-card-image {
                max-height: 250px;
            }
        }
    }

    mat-paginator {
        padding-right: 70px;
    }

    button.mat-fab-bottom-right {
        z-index: 1;
    }
}

vertical-layout-1 #main > .container > .container > .container content > app-gamecode-content-editor > .page-layout > .content {
    padding: 0 !important;
}

app-gamecode-content-editor {

    mat-spinner {
        margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the page
    }

    .content-editor {
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        place-content: stretch flex-start;
        align-items: stretch;

        .gamecode-header {
            font-style: normal;
            font-size: 20px;
            line-height: 24px;
            padding-left: 21px;
            padding-top: 14px;

            button.mat-mini-fab.mat-accent {
                float: right;
            }
        }

        .gamecode-content {
            height: 100%;
            flex: 1 1 auto;

            iframe {
                border: 0;
                height: 100%;
            }
        }
    }
}


mat-dialog-container.mat-dialog-container app-gamecode-data-editor-modal {
    .mat-dialog-content {
        padding: 0;

        form {
            padding: 0 10px 10px 10px;

            mat-list-item {
                mat-spinner {
                    margin: 0;
                }
            }
        }

        mat-spinner {
            margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the modal
        }

        .form-content {
            padding: 0 20px;
        }
    }
}

app-gamecode-card.card-split-media-info {
    .mat-card.mat-card { // TODO surcharge inutile voir en amont
        .mat-card-header {
            background-color: $card__header--gamecode-card--background-color;
            .mat-card-title * {
                color: $card__header--gamecode-card--color;
            }
        }
    }



    mat-card-content {
        height: 100%;

    }

    mat-card-actions.mat-card-actions {
        place-content: flex-end;
    }

}