@use 'node_modules/@angular/material/index' as mat;

&.mat-card {
    @include reset-card;
    @include g-3;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--text, #006AA6); // à passer dans la config Mat
    background-color: unset;

    &:not(:has(.mat-card-header)), // Does not contain mat-card-header
    &:has(.mat-card-header:empty) { // Or contains empty mat-card-header
        @include pt-3;
    }

    &:not(:has(.mat-card-footer)), // Does not contain mat-card-footer
    &:has(.mat-card-footer:empty) { // Or contains empty mat-card-footer
        @include pb-3;
    }
}