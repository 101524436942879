// --------------------------------------------------
// MARGIN (ALL SIDES)
// --------------------------------------------------

@mixin m-0 {
    margin: $spacing-0;
}

@mixin m-1 {
    margin: $spacing-1;
}

@mixin m-2 {
    margin: $spacing-2;
}

@mixin m-3 {
    margin: $spacing-3;
}

@mixin m-4 {
    margin: $spacing-4;
}

@mixin m-5 {
    margin: $spacing-5;
}

@mixin m-auto {
    margin: $spacing-auto;
}

// --------------------------------------------------
// MARGIN-BLOCK (TOP & BOTTOM)
// --------------------------------------------------

@mixin my-0 {
    margin-block: $spacing-0;
}

@mixin my-1 {
    margin-block: $spacing-1;
}

@mixin my-2 {
    margin-block: $spacing-2;
}

@mixin my-3 {
    margin-block: $spacing-3;
}

@mixin my-4 {
    margin-block: $spacing-4;
}

@mixin my-5 {
    margin-block: $spacing-5;
}

@mixin my-auto {
    margin-block: $spacing-auto;
}

// --------------------------------------------------
// MARGIN-INLINE (LEFT & RIGHT)
// --------------------------------------------------

@mixin mx-0 {
    margin-inline: $spacing-0;
}

@mixin mx-1 {
    margin-inline: $spacing-1;
}

@mixin mx-2 {
    margin-inline: $spacing-2;
}

@mixin mx-3 {
    margin-inline: $spacing-3;
}

@mixin mx-4 {
    margin-inline: $spacing-4;
}

@mixin mx-5 {
    margin-inline: $spacing-5;
}

@mixin mx-auto {
    margin-inline: $spacing-auto;
}

// --------------------------------------------------
// MARGIN-TOP
// --------------------------------------------------

@mixin mt-0 {
    margin-top: $spacing-0;
}

@mixin mt-1 {
    margin-top: $spacing-1;
}

@mixin mt-2 {
    margin-top: $spacing-2;
}

@mixin mt-3 {
    margin-top: $spacing-3;
}

@mixin mt-4 {
    margin-top: $spacing-4;
}

@mixin mt-5 {
    margin-top: $spacing-5;
}

@mixin mt-auto {
    margin-top: $spacing-auto;
}

// --------------------------------------------------
// MARGIN-BOTTOM
// --------------------------------------------------

@mixin mb-0 {
    margin-bottom: $spacing-0;
}

@mixin mb-1 {
    margin-bottom: $spacing-1;
}

@mixin mb-2 {
    margin-bottom: $spacing-2;
}

@mixin mb-3 {
    margin-bottom: $spacing-3;
}

@mixin mb-4 {
    margin-bottom: $spacing-4;
}

@mixin mb-5 {
    margin-bottom: $spacing-5;
}

@mixin mb-auto {
    margin-bottom: $spacing-auto;
}

// --------------------------------------------------
// MARGIN-LEFT
// --------------------------------------------------

@mixin ml-0 {
    margin-left: $spacing-0;
}

@mixin ml-1 {
    margin-left: $spacing-1;
}

@mixin ml-2 {
    margin-left: $spacing-2;
}

@mixin ml-3 {
    margin-left: $spacing-3;
}

@mixin ml-4 {
    margin-left: $spacing-4;
}

@mixin ml-5 {
    margin-left: $spacing-5;
}

@mixin ml-auto {
    margin-left: $spacing-auto;
}

// --------------------------------------------------
// MARGIN-RIGHT
// --------------------------------------------------

@mixin mr-0 {
    margin-right: $spacing-0;
}

@mixin mr-1 {
    margin-right: $spacing-1;
}

@mixin mr-2 {
    margin-right: $spacing-2;
}

@mixin mr-3 {
    margin-right: $spacing-3;
}

@mixin mr-4 {
    margin-right: $spacing-4;
}

@mixin mr-5 {
    margin-right: $spacing-5;
}

@mixin mr-auto {
    margin-right: $spacing-auto;
}

// --------------------------------------------------
// PADDING (ALL SIDES)
// --------------------------------------------------

@mixin p-0 {
    padding: $spacing-0;
}

@mixin p-1 {
    padding: $spacing-1;
}

@mixin p-2 {
    padding: $spacing-2;
}

@mixin p-3 {
    padding: $spacing-3;
}

@mixin p-4 {
    padding: $spacing-4;
}

@mixin p-5 {
    padding: $spacing-5;
}

// --------------------------------------------------
// PADDING-BLOCK (TOP & BOTTOM)
// --------------------------------------------------

@mixin py-0 {
    padding-block: $spacing-0;
}

@mixin py-1 {
    padding-block: $spacing-1;
}

@mixin py-2 {
    padding-block: $spacing-2;
}

@mixin py-3 {
    padding-block: $spacing-3;
}

@mixin py-4 {
    padding-block: $spacing-4;
}

@mixin py-5 {
    padding-block: $spacing-5;
}

// --------------------------------------------------
// PADDING-INLINE (LEFT & RIGHT)
// --------------------------------------------------

@mixin px-0 {
    padding-inline: $spacing-0;
}

@mixin px-1 {
    padding-inline: $spacing-1;
}

@mixin px-2 {
    padding-inline: $spacing-2;
}

@mixin px-3 {
    padding-inline: $spacing-3;
}

@mixin px-4 {
    padding-inline: $spacing-4;
}

@mixin px-5 {
    padding-inline: $spacing-5;
}

// --------------------------------------------------
// PADDING-TOP
// --------------------------------------------------

@mixin pt-0 {
    padding-top: $spacing-0;
}

@mixin pt-1 {
    padding-top: $spacing-1;
}

@mixin pt-2 {
    padding-top: $spacing-2;
}

@mixin pt-3 {
    padding-top: $spacing-3;
}

@mixin pt-4 {
    padding-top: $spacing-4;
}

@mixin pt-5 {
    padding-top: $spacing-5;
}

// --------------------------------------------------
// PADDING-BOTTOM
// --------------------------------------------------

@mixin pb-0 {
    padding-bottom: $spacing-0;
}

@mixin pb-1 {
    padding-bottom: $spacing-1;
}

@mixin pb-2 {
    padding-bottom: $spacing-2;
}

@mixin pb-3 {
    padding-bottom: $spacing-3;
}

@mixin pb-4 {
    padding-bottom: $spacing-4;
}

@mixin pb-5 {
    padding-bottom: $spacing-5;
}

// --------------------------------------------------
// PADDING-LEFT
// --------------------------------------------------

@mixin pl-0 {
    padding-left: $spacing-0;
}

@mixin pl-1 {
    padding-left: $spacing-1;
}

@mixin pl-2 {
    padding-left: $spacing-2;
}

@mixin pl-3 {
    padding-left: $spacing-3;
}

@mixin pl-4 {
    padding-left: $spacing-4;
}

@mixin pl-5 {
    padding-left: $spacing-5;
}

// --------------------------------------------------
// PADDING-RIGHT
// --------------------------------------------------

@mixin pr-0 {
    padding-left: $spacing-0;
}

@mixin pr-1 {
    padding-left: $spacing-1;
}

@mixin pr-2 {
    padding-left: $spacing-2;
}

@mixin pr-3 {
    padding-left: $spacing-3;
}

@mixin pr-4 {
    padding-left: $spacing-4;
}

@mixin pr-5 {
    padding-left: $spacing-5;
}

// --------------------------------------------------
// GAP
// --------------------------------------------------

@mixin g-0 {
    gap: $spacing-0;
}

@mixin g-1 {
    gap: $spacing-1;
}

@mixin g-2 {
    gap: $spacing-2;
}

@mixin g-3 {
    gap: $spacing-3;
}

@mixin g-4 {
    gap: $spacing-4;
}

@mixin g-5 {
    gap: $spacing-5;
}