
%compass-typography {
    .compass__back__link {
        padding-top: 25px;
        color: #2A2A2A;
        /* Desktop/button */
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 114.286% */

        display: flex;
        gap: 10px;
        align-items: center;

        //text-decoration: none;
    }

    h2 {
        color: #343B4A;
        font-size: 2rem;
        font-style: normal;
        font-weight: 900;
        line-height: 21px; /* 105% */
    }
    h3 {
        color: #333A49;
        font-size: 2rem;
        font-style: normal;
        font-weight: 900;
        line-height: 22px; /* 122.222% */
    }
    h4 {
        color: #333A49;
        font-weight: 900;
    }
    p {
        color: #333A49;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 13.2px */
    }

}

$compassColorsSecondary: (
    conseils_pratiques: #DD8737,
    propos_denseignants: #2078B7,
    collection: #169E95,
    parcours_dautoformation: #E45F65,
    parcours-pedagogique: #E4608F,
    kit_pedagogique: #5B67D0,
    conseils_dexperts: #2078B7,
);

$compassColorsPrimary: (
    diagnostics: #9342D2,
    skills: #5BC0EB,
    difficulty: #F2545B,
    orga: #5E2BFF,
    peda: #FE9920
);



$compasNavButtonColorSet: (
    compass-theme0: ( // diagnostic
        background-color: map-get($compassColorsPrimary, diagnostics),
        color: #FFF,
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-01.svg)
    ),
    compass-theme1: ( // skills
            background-color: map-get($compassColorsPrimary, skills),
            color: #FFF,
            background-image: url(/assets/inclusive/images/backgrounds/PERSO-02.svg)
        ),
    compass-theme2: ( // difficulty
            background-color: map-get($compassColorsPrimary, difficulty),
            color: #FFF,
            background-image: url(/assets/inclusive/images/backgrounds/PERSO-03.svg)
        ),
    compass-theme3: (
            background-color: map-get($compassColorsPrimary, orga),
            color: #FFF,
            background-image: url(/assets/inclusive/images/backgrounds/PERSO-04.svg)
        ),
    compass-theme4: (
            background-color: map-get($compassColorsPrimary, peda),
            color: #FFF,
            background-image: url(/assets/inclusive/images/backgrounds/PERSO-05.svg)
        ),
    compass-theme5: (
        background-color: #dd54f2,
        color: #FFF,
        background-image: url(/assets/inclusive/images/backgrounds/PERSO-06.svg)
    )
);
@include classesGenerator('.', $compasNavButtonColorSet);



app-compass {
    border: 1px solid #FFF;
    background: #D8DCE4;

    @include genericInnerPageLayout;
    @extend %compass-typography;

    h2 a {
        text-decoration: none;
    }

    .compass__explorer__nav {
        @include gridTemplate($tpl-compass);
        min-height: 25vh;

        &__title {
            font-size: 1.5rem;
            font-weight: bold;
        }

        &__button {
            @include button('compass', $bordered-gradient: false);
            font-size: 2rem;
            line-height: 2rem;
            align-items: center;
            color: $white-c;
            margin: 0;
            padding: 0;
            display: flex;
            gap: 0;
            background-repeat: no-repeat;
            background-position: bottom right;
            background-size: auto 100%;

            mat-icon.mat-icon {
                order: 2;
                flex: 1;
                margin: 0;
                // background-color: #1227a9;
                width: unset;
                display: flex;
                justify-content: flex-start;
                svg {
                    width: unset;
                }
            }

            span {
                order: 1;
                flex: 2;
                display: flex;
                align-items: center;
                padding: 8px 14px !important;
                //background-color: orange;
            }

            .compass__explorer__nav__button__media {
                flex: 2;
                order: 3;
                align-self: stretch;
                max-width: 90px;
                position: relative;
            }




            &:nth-child(1), &:nth-child(2) {
                flex-direction: column;
                background-position: -20px 130px;
                background-size: contain;
                padding: 0 14px;
                span { flex: 1; }
                .mat-icon { flex: 1; align-items: flex-start; align-self: flex-end;
                    max-width: 24px;
                    svg {
                        max-width: 24px;
                        max-height: 24px;
                    }
                }
            }
            &:nth-child(1) {
                background-position: bottom center;
                background-size: auto 50%;
                .mat-icon {
                    align-items: flex-start;
                }
            }
            &:nth-child(2) {
                flex-direction: column-reverse;
                color: $default-font-color;
                background-position: top left;
                background-size: 50% 50%;
                align-items: flex-end;
                .mat-icon { align-items: flex-end; 
                   color: currentColor !important;
                          }
            }
            &:nth-child(4), &:nth-child(5) { background-image: none; }
        }
    }

    .compass__news__content.grid-compass {
        padding: unset;
        background-color: unset;
    }

}

.grid-compass {
    @include grid($sm-col: 1, $lg-col: 2, $xl-col: 3, $gap: 18);

    padding: 20px;
    border-radius: 18px;
    background: rgba(50, 58, 73, 0.05);
}

.mat-progress-spinner {
    margin: auto;
}

//.compass-themes__header {




.compass-themes__content {
    &.grid-compass {
        border-radius: 0;
    }
}

