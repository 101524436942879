app-notepad-list {

    .notepad-card-list {

        .fake-card .mat-card {
            background-color: $fake-card--background-color;
            color: $fake-card--color;
        }
    }

    button[mat-fab].mat-fab.mat-fab-bottom-right {
        background-color: $accent-c;

        svg {
            path {
                stroke: $default-font-color;
            }
        }
    }
}

app-notepad-card {

    .mat-card {

        .mat-card-header {
            background-color: $card__header--breadcrumb--background-color !important;

            .mat-card-title * {
                color: $card__header--breadcrumb--color !important;
                text-transform: uppercase;
            }

            button {

                .mat-icon {
                    color: inherit;
                }
            }
        }
    }
}

app-notepad-data-editor-modal {
    span.mat-form-field-label-wrapper label {
        color: $default-font-color !important;
    }
}