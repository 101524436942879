mat-icon.mat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 24px;
    width: 24px;
    height: 24px;

    svg {
        width: 24px;
        height: 24px;
        min-height: 24px; // **1
        min-width: 24px; // **1
    }
}

.bem-header__btn-menu__icon {
    width: 32px;
    height: 32px;

    &[role="button"] {
        display: flex;
        justify-content: center;
        width: auto;
        height: auto;
    }
}

// ------------------ Note
/*
**1 - Quand l'icone n'est pas encapsulé dans un bouton :( mais fait fonction.
Le svg est définit pour être flexible
*/