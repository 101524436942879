// --------------------------------------------------
// ACCESSIBLE HIDING (https://a11y-guidelines.orange.com/en/articles/accessible-hiding/)
// --------------------------------------------------

@mixin visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.visually-hidden { // .cdk-visually-hidden possible aussi
    @include visually-hidden;
}

// --------------------------------------------------
// FOCUS-VISIBLE
// --------------------------------------------------

@mixin a11y-focus-visible {
    outline: 2px auto Highlight !important; // Firefox based browser default outline style and color (accessibility best practice)
    outline: 2px auto -webkit-focus-ring-color !important; // Chrome based browser default outline style and color (accessibility best practice)
}