body {
    color: $basic-page--color;
    background-color: $body--background-color;

    .mat-white-bg { // TODO remove from HTML background-color should be on body css
        background-color: var(--body--background-color) !important;
    }
}

.page-layout {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.top-main-toolbar {
    background-color: var(--faux_blanc);
    color: var(--anthracite) !important;

    .toolbar-separator {
        display: none;
    }

    .user-button {
        .account-info {
            display: flex;
            flex-direction: row-reverse;

            .material-icons {
                font-weight: bold;
            }
        }
    }
}

#container-3 {
    > content {

        app-single-graph-view,
        app-multi-graph-view,
        app-notepad-list,
        app-mindmap-list,
        app-gamecode-list {

            .page-layout > .content {
                padding: 16px;
            }
        }
    }

}

/* Lesson page */
app-lesson-page {

    .header{
        align-items: center;

        button {
            min-width: auto;

            .mat-button-wrapper {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .breadcrumb{
            text-transform: uppercase;
            font-weight: bold;
            ul{
                padding: 0 15px;
                margin: 0;
            }
            li{
                display: inline-block;
                .lesson-card-breadcrump__nav__item__separator {
                    padding: 0 5px;
                }
                &.concept, &.chapter {
                    color: $breadcrumb__ul__li--color;
                    a {
                        text-decoration: underline;
                        color: $breadcrumb__ul__li--color;
                    }
                    .lesson-card-breadcrump__nav__item__separator {
                        color: $breadcrumb__ul__li--color;
                    }
                }
                &.title a {
                    color: $default-font-color;
                }
            }
        }
    }

    .lesson-section {
        display: flex;
        flex-direction: column;
        gap: 32px;
        min-width: fit-content;
        padding: 16px;
        background-color: var(--faux-blanc);
        border: $lesson-page__lesson-section--border;
        border-radius: 0 0 5px 5px;
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.10)); 

        .lesson-wrapper {
            display: flex;
            gap: 16px;

            .lesson-thumbnail {
                flex: 0 0 200px;

                img {
                    border: 1px solid var(--gris-de-contour);
                    border-radius: 5px;
                }
            }

            .lesson-content {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .lesson-title {

                    > h1 {
                        font-weight: 700;
                        text-transform: uppercase;
                        margin: 0;
                    }
                }

                .lesson-author {
                    width: fit-content;
                    padding: 10px;
                    background-color: $lesson-page__generic-element--background-color;
                    border-radius: 5px;

                    > span:last-child {
                        font-weight: 700;
                    }
                }

                .lesson-description {

                    > p {
                        margin: 0;
                    }
                }

                .lesson-tags {

                    mat-chip {
                        background-color: $standard-chip--background-color;
                        color: $standard-chip--color;

                        a {
                            color: $standard-chip--color;
                        }
                    }
                }
            }

            .lesson-actions {
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding-left: 64px;
                margin-left: auto;

                > div {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                    button {
                        margin: 0;

                        .mat-button-wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 16px;
                        }
                    }

                    &:last-child {

                        button {
                            @extend %button--inverted
                        }
                    }
                }
            }
        }

        .lesson-files {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px;
            border-radius: 5px;
            background-color: $lesson-page__generic-element--background-color;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04) inset;

            .lesson-files-title {
                display: flex;
                align-items: center;
                gap: 8px;

                h2 {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 21px;
                    margin: 0;
                }
            }

            .file-cards-wrapper {
                display: flex;
                overflow-x: hidden;

                .custom-nav {
                    position: absolute;
                    top: 10px;
                    right: 16px;
                    display: flex;
                    gap: 10px;

                    #prev, #next {
                        @extend %button--square-icon;
                        margin: 0;
                        border: none !important;
                        background-color: white !important;

                        .mat-icon {
                            color: $default-font-color;
                        }
                    }

                    #prev {

                        mat-icon{
                            rotate: 180deg;
                        }
                    }
                }

                mat-card {
                    align-items: center;
                    background: white;
                    border: 1px solid var(--gris-de-contour);
                    border-radius: 5px;
                    cursor: pointer;
                    margin-right: 16px;
                    overflow-wrap: anywhere;

                    mat-card-content {
                        display: flex;

                        mat-card-title {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }

                    mat-icon {
                        height: 54px;
                        width: 54px;
                        min-height: 54px;
                        min-width: 54px;
                        padding: 5px;
                        margin-right: 10px;
                        color: $lesson-page__file-card--color;
                        border: 1px solid $lesson-page__file-card--color;
                        border-radius: 5px;
                        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
                    }
                }
            }
        }
    }
}