@use '@angular/material' as mat;
@import '@angular/material/theming';


/* http://mcg.mbitson.com/#!?rose=%23ee0072&blue=%2300afec&orange=%23fcb731&gray=%23939598&bluedark=%23004157&themename=mathia#%2F */


$mat-white: (
    500: white,
    contrast: (
        500: $black-87-opacity
    )
);

$mat-black: (
    500: black,
    contrast: (
        500: white,
    )
);

$md-blue: (
    50 : #e6f5fc,
    100 : #c1e7f7,
    200 : #97d7f2,
    300 : #6dc6ed,
    400 : #4ebae9,
    500 : #2faee5,
    600 : #2aa7e2,
    700 : #239dde,
    800 : #1d94da,
    900 : #1284d3,
    A100 : #ffffff,
    A200 : #cfeaff,
    A400 : #9cd3ff,
    A700 : #82c8ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-accent: (
    50 : #fce5e3,
    100 : #f8beb9,
    200 : #f3938b,
    300 : #ee685c,
    400 : #ea4739,
    500 : #e62716,
    600 : #e32313,
    700 : #df1d10,
    800 : #db170c,
    900 : #d50e06,
    A100 : #fffdfd,
    A200 : #ffcbca,
    A400 : #ff9997,
    A700 : #ff807e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-red: (
    50 : #fce5e3,
    100 : #f8beb9,
    200 : #f3938b,
    300 : #ee685c,
    400 : #ea4739,
    500 : #e62716,
    600 : #e32313,
    700 : #df1d10,
    800 : #db170c,
    900 : #d50e06,
    A100 : #fffdfd,
    A200 : #ffcbca,
    A400 : #ff9997,
    A700 : #ff807e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$quaternary: $md-blue;
$mat-fusedark: (
    50 : #e6e7e9,
    100 : #c2c4c8,
    200 : #999da4,
    300 : #707580,
    400 : #515864,
    500 : #323a49,
    600 : #2d3442,
    700 : #262c39,
    800 : #1f2531,
    900 : #131821,
    A100 : #659dff,
    A200 : #327dff,
    A400 : #005cfe,
    A700 : #0053e5,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

// Define the Material palettes
$primary: mat.define-palette($mat-fusedark, 500);
$accent: mat.define-palette($md-accent, 500);
$warn: mat.define-palette($md-red, 500);

// Force the input field font sizes to 16px
$typography: mat.define-legacy-typography-config(
    $font-family: '#{var(--typo1)}, "Helvetica Neue", Arial, sans-serif',
    $body-1: mat.define-typography-level(14px, 1.4, 400),
    $input: mat.define-typography-level(14px, 1.125, 300, #{var(--typo1)}, 0.25),
    $title: mat.define-typography-level(14px, 16px, 700, #{var(--typo1)}), // h1, h2 ...
    $subheading-2: mat.define-typography-level(14px, 16px, 700, #{var(--typo1)}) // h3
);

// Create the Material theme object
$theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn:$warn
    ),
    typography: $typography,
    density: 0,
));

// Store the background and foreground colors for easier access
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);


$activities-card: mat.define-legacy-typography-config(
    $title: mat.define-typography-level(14px, 17px, 700, #{var(--typo1)}, 0.005em)
);

