app-tool {
    
    .tool__card {

        .tool__content {
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include g-2;

            .tool__image-wrapper,
            .tool__icon-wrapper {
                width: auto;
                min-width: 100px;
                max-width: 250px;
                height: auto;
                min-height: 100px;
                max-height: 250px;

                img,
                svg {
                    width: 100%;
                    height: 100%;
                }
            }

            .tool__execute-button {
                @include primary-button;
            }
        }
    }
}