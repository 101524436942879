app-mindmap-list {

    .mindmap-card-list {

        .fake-card .mat-card {
            background-color: $fake-card--background-color;
            color: $fake-card--color;
        }
    }
}

app-mindmap-card {

    .mat-card {

        .mat-card-header {
            background-color: $card__header--breadcrumb--background-color !important;

            .mat-card-title * {
                color: $card__header--breadcrumb--color !important;
                text-transform: uppercase;
            }

            button {

                .mat-icon {
                    color: inherit;
                }
            }
        }
    }
}