@use "sass:string";
@use 'sass:list';
@use 'sass:map';

@function getTemplateRows($str) {
    $lines: string.split($str, ',');
    @return list.length($lines);
}
@function getTemplateColumns($str) {
    $lines: string.split($str, ',');
    $cols: string.split(list.nth($lines, 1), ' ');
    @return list.length($cols);
}
@function getTemplateArea($str) {
    $lines: string.split($str, ',');
    $tplArea:'';
    @each $l in $lines {
        $tplArea: $tplArea + '"' + $l + '"';
    }
    @return string.unquote($tplArea);
}

@mixin genericInnerPageLayout {
    gap: 16px;
    > * {
        @include px-3;
    }
}
// build mixin gridTemplate
// https://sass-lang.com/playground/#eJyVU9uO2jAQfc9XjFgkJ90ASvtSmReq/YOq78g4Q7DqOGnsbFkh/r1jx0C4rCr8kNhzOXM7UxSr3iJMrLCWW9cpU02WSZCxINPKOnYlqUVLgmS17Y10qjFQofuFdauFw5/NX5tOCSeDQwJ0ploZtBwG6LlttXLBIAeWs2wZjFYdur4z4GPNNZrK7dLBkQyOjyO9NbqvjY1Yg/Vb0xuHHQfTa/1UBlPZ6FubkI05p5JDkf03aQ/zec4/OhTPd2fqWu09OWNesFjAqsRNX0XvmA0KuSMJKBPlMcTI/3yDV2ATRl+yD9cB45hcqooZ9eZP3zhMT56htsS/ZrKpW08HSIMziTgwARuQIHP/LwFZHnSVaDkU34eHbMxWVSc3fygzVsACbCsMfI0+/mw+U0hSfHukKEkxeuLlmSWZJ22t9tShqlPlaSgQisuGbpWKZiA+eLAYuuJvs1DBy4GoP6d5Bo/cl5W9snbPjiNLF2FnHa0Chw5bFC6925FrJPpABkSvYttB9ghNDnR/CHhahWcxBQ2UEG8JCnc4GVzWxGz5jcEwUN/dERF/4wdt5bvQPQZKeqMzIwlIcRKXuE+DJg8O0T7G8mfEdRXx/fnCaS1ncqd0mb4cpuqYjaDPdYqB8wS8vFfSdHiMd9Eek7gHdJlHfq/XuG9102G3XhvxPgq0UkbqvsQrNqXj3Ri25R8G6pGW

$tpl-compass: (
    tpl: 'a b c c,a b d e',
    gap: 18,
    config: (
        a: '1 / span 2',
        b: '1 / span 2',
        c: '3 / span 2',
        d: '',
        e: '',
    )
);

@mixin gridTemplate ($tpl){
    display: grid;
    grid-gap: #{map.get($tpl, gap)+'px'};
    grid-template-rows: repeat( getTemplateRows(map.get($tpl, tpl ) ), 1fr );
    grid-template-columns: repeat( getTemplateColumns(map.get($tpl, tpl ) ), 1fr );
    grid-template-areas: getTemplateArea(map.get($tpl, tpl ));
    $conf: map.get($tpl, config);

    @each $key, $value in $conf {
        $i: index($conf, $key $value);
        > *:nth-child(#{$i}) {
            grid-area: $key;
            // grid-row: string.unquote($value);
        }
    }
}