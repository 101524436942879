app-compass-resources {
    border: 1px solid #FFF;
    background: #D8DCE4;

    @include genericInnerPageLayout;
    @extend %compass-typography;

    .compass-resources__header {
        flex: none;
        display: flex;
        position: relative;
        flex-direction: column;
        gap: 10px;
        overflow: hidden;
        padding: 20px;
        border-radius: 18px 18px 0 0;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: bottom right;

        > * {
            z-index: 1;
            margin: 0;
            max-width: 65%;
            // color: white;
        }

    }

    .compass-ressources__content {
        border-radius: 0 0 18px 18px;
        background: #EFF1F5;
    }
}