app-pretty-activity-card {
    aspect-ratio: 8/12;
    display: flex;

    border-radius: 7px;
    overflow: hidden;
    border: 1px solid var(--gris-de-contour, #CBD1DB);
    background: var(--faux-blanc, #F9FBFF);
    box-shadow: 0 10px 15px 0 rgba(36, 38, 55, 0.10), 0 2px 2px 0 rgba(36, 38, 55, 0.05), 0 4px 4px 0 rgba(255, 255, 255, 0.25) inset;

    // see app-pretty-card.scss

    &.active {
        box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.25);
    }
}

