app-diagnostic {
    border: 1px solid #FFF;
    background: #D8DCE4;

    @include genericInnerPageLayout;
    @extend %compass-typography;

    > section {

    }

    .compass-themes__header {
        flex: none;
        display: flex;
        position: relative;
        flex-direction: column;
        gap: 10px;
        overflow: hidden;
        padding: 20px;
        border-radius: 18px;
        padding: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 90% bottom;
        min-height: 100px;
        justify-content: center;

        h2 {
            font-size: 2.4rem;
            font-weight: bold;
        }
        p {
                font-size: 1.4rem;
                font-weight: normal;
                line-height: 2rem;
        }

        h2, p {
            color: white;
        }

        > * {
            z-index: 1;
            margin: 0;
            max-width: 65%;
            @include media-breakpoint-up(md) {
                max-width: 85%;
            }
            // color: white;
        }

    }

    .compass-themes__content {
        padding: 20px;
        border-radius: 18px 18px 0 0;
        background: rgba(50, 58, 73, 0.05);
        gap: 0;
        display: flex;
        flex-direction: column;

        .grid-compass {
            padding: unset;
            border-radius: unset;
            background-color: unset;
        }
    }

}

.compass-themes__filter {
    display: flex;
    gap: 20px;

    .mat-chip.mat-standard-chip, .mat-mdc-chip.mat-mdc-standard-chip  {
        @include chips(dark);
        border: 1px solid $default-font-color;
    }

    .mat-form-field.compass-themes__filter__list, .mat-form-field.compass-themes__filter__list__levels {
        flex: 1;
        .mat-form-field-wrapper {

            .mat-form-field-flex {
                padding: 0 15px;
                background: #FFF;
                border-radius: 8px;
                border: 1px solid rgba(255, 255, 255, 0.00);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
            }
            .mat-form-field-underline {
                display: none;
            }

        }
    }
    .mat-form-field.compass-themes__filter__list {
        flex: 3;
        .mat-form-field-flex::before {
            content: '#';
            font-weight: 700;
            font-size: 2rem;
            padding-right: 10px;
        }
    }
    .mat-form-field.compass-themes__filter__list__levels {
        .mat-form-field-flex::before {
            content: "";
            top: 6px;
            width: 25px;
            height: 25px;
            display: block;
            color: white;
            position: relative;
            background-color: #{$black-c};
            mask-image: icon('school.svg');
            mask-size: contain;
            mask-repeat: no-repeat;
            padding-right: 40px;

        }

    }


    > button {
        @include button( "tertiary", "s", $icon-full: true, $text-hide: true );
        margin: 0;
        margin-bottom: 1.34375em;
        flex: none;
        min-width: 46px;
        height: 46px;
        color: red;
        span {
            @extend .cdk-visually-hidden;
        }


        mat-icon.mat-icon {
            width: 2.6rem;
            height: 2.6rem;
            color: currentColor !important;
        }
    }
}