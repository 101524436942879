bdtool-pagination {

    nav {

        ul {
            margin: unset;
            gap: 1rem !important;
            padding: 2rem !important;

            li {
                width: 4rem !important;
                height: 3rem !important;
                margin: unset !important;
                background-color: $bd-tool__pagination-link--background-color !important;
                color: $bd-tool__pagination-link--color !important;
                box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25) !important;
                font-size: 1.6rem;

                &.active {
                    background-color: $bd-tool__pagination-link--active--background-color !important;
                    color: $bd-tool__pagination-link--active--color !important;
                }

                &:has(img) { // Previous and next buttons
                    position: relative;
                    width: auto !important;
                    aspect-ratio: 1;
                    padding: 0.2rem !important;
                    background-color: $bd-tool__pagination-button--background-color !important;

                    &::before {
                        content: '';
                        position: absolute;
                        inset: 0;
                        padding: inherit;
                        background-color: $bd-tool__pagination-button--color;
                        mask-origin: content-box;
                        mask-size: contain;
                        mask-position: center;
                        mask-repeat: no-repeat;
                        pointer-events: none;
                    }

                    img {
                        display: none;
                    }

                    &:first-child { // Previous button
                        margin-right: 0.5rem !important;

                        &::before {
                            mask-image: icon('arrow_left.svg');
                        }
                    }

                    &:last-child { // Next button
                        margin-left: 0.5rem !important;

                        &::before {
                            mask-image: icon('arrow_right.svg');
                        }
                    }
                }
            }
        }
    }
}