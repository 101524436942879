.onboard-dialog {
    position: absolute !important;
    top: 1%;
    right: 1%;
    width: 330px;
    min-width: 330px;

    mat-dialog-container.mat-dialog-container {
        width: 100% ;
        min-width: 330px;
        max-width: 100%;
        max-height: inherit;
        border-radius: 25px;
        border: none;
        background-color: #F7F7F7;

        .mat-dialog-title {
            color: #3A4354;
            background-color: #F7F7F7;
            padding-left: 27px;
            margin: 0;
            align-items: start;

            > mat-icon.mat-icon {
                position: absolute;
                top: 30px;
            }

            h2 {
                font-size: 18px;
                line-height: normal;
                margin-top: 55px;
            }
        }

        .mat-dialog-content {
            padding: 0 27px;
            min-width: 330px;

            div {
                &.page-layout.simple {
                    padding: 0;
                }

                p {
                    font-size: 12px;
                }

                app-choose-group {
                    .embed-container {
                        display: flex;
                        flex-direction: column;
                    }
                }
            }
        }

        .mat-dialog-actions {
            padding: 0 27px 20px;

            button {

                &.inverted {
                    @extend %button--inverted;
                    border: none !important;
                }
            }

            app-button-block {
                width: 100%;
                font-size: 12px;

                p {
                    margin-bottom: 0;
                    font-style: italic;
                    font-weight: 900;
                }

                button {
                    text-align: left;
                    margin-top: 10px;
                    width: 100%;
                    font-size: 12px;

                    .mat-button-wrapper {
                        display: inline-flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;

                        span {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }

                a {
                    display: block;
                    margin-top: 20px;
                    color: $dialog__link--color;
                    text-decoration: underline;
                    cursor: pointer;
                }

                &.right {
                    text-align: right;
                    width: 50%;
                    order: 1;

                    & + app-button-block {
                        width: 50%;
                    }
                }
            }

            app-field-block {
                width: 100%;
                font-size: 12px;

                p {
                    margin-bottom: 0;
                    font-style: italic;
                    font-weight: 900;
                }

                mat-chip {
                    &.mat-accent {
                        color: white;
                        background-color: $accent-c;
                        border: 1px solid map_get($md-accent, 900);
                    }

                    font-weight: 700;
                    font-size: 16px;
                    line-height: 1.8;
                    text-transform: uppercase;
                    cursor: pointer;
                    
                    .mat-mdc-chip-action-label {
                        margin-top: 2px;
                    }
                }
            }

            app-collections-block {
                width: 100%;
                font-size: 12px;

                & > div > p {
                    margin-bottom: 0;
                    font-style: italic;
                    font-weight: 900;
                }

                button {
                    text-align: left;
                    text-wrap: initial;
                    line-height: normal;
                    margin-top: 10px;
                    margin-left: 0;
                    padding: 4px;
                    width: 100%;
                    color: black !important;

                    .mat-button-wrapper {
                        display: inline-flex;
                        flex-direction: row;
                        gap: 10px;
                        align-items: center;
                        width: 100%
                    }

                    img {
                        float: left;
                    }

                    h3 {
                        font-size: 12px;
                        font-weight: 700;
                        margin: 0 0 3px 0;
                    }

                    p {
                        font-size: 10px;
                        font-weight: 600;
                        margin: 0;

                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}