@use "sass:map";

// app-pretty-card button-menu
.mat-menu-panel.editor-panel__button-menu {
    margin-top: 10px;

    .mat-menu-content {
        background: #323A49;

        .mat-menu-item {
            @include button("dark", $icon-on-Right: false, $flat-button: true);
        }
    }
}

app-pretty-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #323A49;
    border: 1px solid #E7E7E7;

    .app-pretty-card__button-menu {
        @include button("tertiary", $size: s, $text-hide: true); // TODO
        box-shadow: 0 10px 15px 0 rgba(36, 38, 55, 0.05), 0 2px 2px 0 rgba(36, 38, 55, 0.05), 0 4px 4px 0 rgba(255, 255, 255, 0.15) inset;
        border: 1px solid #E7E7E7;
        justify-content: center;
        margin-left: auto;
        aspect-ratio: 3 / 2;

        mat-icon.mat-icon {
            color: map.get($button-tertiary, color);
            width: 100%;
            height: 100%;
            position: relative;
            svg {
                width: auto;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
            }
        }
    }

    .app-pretty-card__button-move_right,
    .app-pretty-card__button-move_left {
        @include button("tertiary", $text-hide: true);
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 0;
        flex: 1.6;
        justify-content: center;
        mat-icon.mat-icon {
            width: 100%;
            height: 100%;
            position: relative;
            svg {
                width: auto;
                height: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
            }
        }


        @include media-breakpoint-down("sm") {
            display: none;
        }
    }

    .app-pretty-card__button-move_right {
        margin-left: 0;
        border-left: 1px solid #E7E7E7;
    }

    .app-pretty-card__button-move_left {
        margin-right: 0;
        border-right: 1px solid #E7E7E7;
    }

    .location-form {
        .location-form-denominator {
            @include body-font;
            color: transparentize(#242637, 0.5);
        }
    }

    // Layouts
    .app-pretty-card {
        &__header {
            flex: 0.8;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding: 7px 8px 5px 8px;
            // background-color: aquamarine;
        }

        &__content {
            flex: 5;
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            max-height: 88%;

            // --------------------------------------------------
            // La Consigne
            // --------------------------------------------------
            h3.pretty-activity-card__activity-name {
                margin: 0;
                text-align: center;
                @include body-font-size;
                word-break: break-all;
            }

            // --------------------------------------------------
            // La prévisualisation
            // --------------------------------------------------
            app-activity-sample {
                display: flex;
                flex: 3;
                flex-direction: column;
                overflow: hidden;
                border-radius: 7px 7px 0 0;
                margin: 0 8px;
                position: relative;
                background-color: $primary-c;
                color: $default-font-contrast-color;
                max-height: 60%;

                .preview {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;

                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    z-index: 0;
                    overflow: hidden;
                    &::before { // overlay color
                        content: " ";
                        width: 100%;
                        display: block;
                        height: 100%;
                        background: transparentize($primary-c, 0.5);
                        background: linear-gradient(0deg, darken($primary-c, 14.12) 0%, transparentize($primary-c, 0.5) 100%);
                        position: absolute;
                        z-index: 2;
                    }
                    & > * {
                        min-width: 100%;
                        min-height: 100%;
                        z-index: 1;
                    }
                }


                @mixin previewCarousel {
                    flex: 1;
                    flex-direction: row;
                    position: relative;
                    max-width: 100%;
                    max-height: 100%;

                    object-fit: unset;
                    height: unset;
                    width: unset;
                    padding: 8px;
                    gap: 0 4px;

                    background-color: #F6F6F7;
                    border: 1px solid #E8E8E8;

                    &::before { // overlay color
                        display: none;
                    }
                    > * {
                        flex: 1;
                        object-fit: cover;
                        object-position: center;
                        height: 100%;
                        width: 50px;
                        min-width: unset !important;
                        min-height: unset !important;
                        max-width: unset !important;
                        max-height: unset !important;
                        display: block;
                    }
                    img {
                        position: absolute;
                        height: auto;
                        aspect-ratio: 16 / 9;
                        border-radius: 7px;
                        border: 1px solid #E7E7E7;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(255, 255, 255, 0.00) 18.9%), rgba(255, 255, 255, 0.80);
                        box-shadow: 0px 10px 15px 0px rgba(36, 38, 55, 0.10), 0px 2px 2px 0px rgba(36, 38, 55, 0.05), 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset;
                        backdrop-filter: blur(5px);
                        &:nth-child(1) { z-index: 3; transform-origin: top right; }
                        &:nth-child(2) { z-index: 4; transform-origin: left bottom; }
                        &:nth-child(3) { z-index: 2; transform-origin: top left; }
                        &:nth-child(4) { z-index: 1; transform-origin: top left; }
                    }
                    // If two images
                    &.imgCounter-2 {
                        img:nth-child(1) {
                            width: 60%;
                            top: 5%;
                            right: 3%;
                        }
                        img:nth-child(2) {
                            width: 60%;
                            bottom: 5%;
                            left: 3%;
                        }
                    }
                    // If three images
                    &.imgCounter-3 {
                        img:nth-child(1) {
                            width: 45%;
                            top: 5%;
                            right: 3%;
                        }
                        img:nth-child(2) {
                            width: 50%;
                            bottom: 5%;
                            left: 23%;
                        }
                        img:nth-child(3) {
                            width: 35%;
                            top: 21%;
                            left: 3%;
                        }
                    }
                    // If four images
                    &.imgCounter-4 {
                        img:nth-child(1) {
                            width: 47%;
                            top: 14%;
                            right: 3%;
                        }
                        img:nth-child(2) {
                            width: 40%;
                            bottom: 5%;
                            left: 25%;
                        }
                        img:nth-child(3) {
                            width: 35%;
                            top: 24%;
                            left: 3%;
                        }
                        img:nth-child(4) {
                            z-index: 1;
                            width: 25%;
                            top: 5%;
                            left: 18%;
                        }
                    }
                }
                .preview.ordonnancement, .preview.awareness {
                    @include previewCarousel;
                }
                @mixin previewVitrine {
                    flex: 1;
                    flex-direction: row;
                    position: relative;
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: unset;
                    height: unset;
                    width: unset;
                    padding: 8px;
                    gap: 0 2px;
                    background-color: #F6F6F7;
                    border: 1px solid #E8E8E8;

                    &::before { // overlay color
                        display: none;
                    }
                    > * {
                        flex: 1;
                        object-fit: cover;
                        object-position: center;
                        height: 100%;
                        width: 50px;
                        min-width: unset !important;
                        min-height: unset !important;
                        max-width: unset !important;
                        max-height: unset !important;
                        display: block;
                    }
                    img {
                        /* position: absolute; */
                        flex: 1;
                        height: 100%;
                        width: 20%;
                        object-fit: cover;
                        /* aspect-ratio: 16 / 9; */
                        border-radius: 2px;
                        border: 1px solid #E7E7E7;
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(255, 255, 255, 0) 18.9%), rgba(255, 255, 255, 0.8);
                        box-shadow: 0px 10px 15px 0px rgba(36, 38, 55, 0.1), 0px 2px 2px 0px rgba(36, 38, 55, 0.05), 0px 4px 4px 0px rgba(255, 255, 255, 0.15) inset;
                        -webkit-backdrop-filter: blur(5px);
                        backdrop-filter: blur(5px);
                    }

                }
                .preview.memory {
                    @include previewVitrine;
                }



            .activity-sample__window-infos,
            .activity-sample__overlay-infos {
                z-index: 5;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5px;
                position: relative;
                padding: 5px;

                font-style: normal;
                font-weight: 800;
                line-height: normal;
                letter-spacing: 0.5px;
                color: #FFF;
                text-shadow: 0 2px 2px rgba(0,0,0,0.3);
                text-align: center;
                > span {
                    padding: 0 10px;
                }
                > *:not(.preview) {
                    z-index: 20;
                }
                mat-icon.mat-icon {
                    width: 37px;
                    height: 35px;
                    position: relative;
                    flex: 0 0 37px;
                    svg {
                        width: auto;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                    }
                }

                mat-icon.mat-icon svg {
                    filter: drop-shadow(0px 1px 2px rgb(0, 0, 0, 0.7));
                }
            }

            .multi-media {
                flex-direction: row;
                text-align: left;
            }

            .activity-sample__window-infos {
                order: 1;
            }
            .activity-sample__typology-trueFalse {
                flex-direction: row;

            }
            .activity-sample__overlay-infos {
                order: 2;
                background-color: $primary-c;
                z-index: 5;

                opacity: 0.9;
            }
            .activity-sample__overlay-infos__QCMU {}
            .activity-sample__icon-jonction {
                position: absolute;
                z-index: 20;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                filter: drop-shadow(0px 1px 2px rgb(0, 0, 0, 0.7));
            }
        }

        // --------------------------------------------------
        // Le type d'exercice
        // --------------------------------------------------
        .pretty-activity-card__activity-name,
        .pretty-activity-card__typology-label {
            margin: 0;
            text-align: center;
            display: flex;
            flex: none;
            padding: 5px 8px;
            align-items: center;
            justify-content: center;
            min-height: 6.5rem;
            line-height: initial;
            word-break: break-all;
        }

        .pretty-activity-card__activity-name {
            @include body-font-size;
        }
        .pretty-activity-card__typology-label {
            border-block: 1px solid #E7E7E7;
        }

        app-audio-time, app-video-time, .time-info {
            display: flex;
            border-radius: 2px;
            background: #FFF;
            color: black;
            padding: 5px 6px 5px 5px;
        }

        // Le bouton Sélectionner ci-dessous
    }

    &__footer {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;
        flex: 1;

        input {
            margin-right: 3px;
            padding: 1px 0 0 0;

            border: 1px solid #CBD1DB;
            color: $primary-c;

            border-radius: 5px;
            @include body-font;
            text-align: center;
            fill: var(--Blanc, #FFF);
            stroke-width: 1px;
            stroke: var(--Grisdecontour, #CBD1DB);

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button { // chrome     hide arrow
                -webkit-appearance: none;
                margin: 0;
            }
            -moz-appearance: textfield; // Firefox hide arrow

        }

        .location-form, .location-form-denominator {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
        }

        .location-form {
            flex: 3;
            display: flex;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;

            background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(255, 255, 255, 0.00) 18.9%), rgba(255, 255, 255, 0.80);
            box-shadow: 0 10px 15px 0 rgba(36, 38, 55, 0.10) inset, 0 2px 2px 0 rgba(36, 38, 55, 0.05) inset, 0 4px 4px 0 rgba(255, 255, 255, 0.15) inset;
            backdrop-filter: blur(5px);

        }
    }
}


// --------------------------------------------------
// Le bouton Sélectionner
// --------------------------------------------------
.app-pretty-card__content + button {
    @include button(primary, $icon-on-Right: true, $fullwidth: true);
    max-height: 12%;
    flex: 1;
    justify-content: center;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

// --------------------------------------------------
// La couverture carte sélectionnée
// --------------------------------------------------
.active & { // = .active app-pretty-card .app-pretty-card__content
    .app-pretty-card__content {
        position: relative;
    }

    .app-pretty-card__content::before {
        position: absolute;
        z-index: 100;
        content: '';
        display: block;
        inset: 0;
        background-color: transparentize($primary-c, 0.1);
        box-shadow: 0 4px 4px 0 rgba(255, 255, 255, 0.25) inset;
    }
    .app-pretty-card__content::after {
        position: absolute;
        z-index: 150;
        content: '';
        display: block;
        inset: 0;
        @include svgBackgroundColor('check.svg', lighten(saturate(#01844d, 1.50), 23.92) );
        mask-repeat: no-repeat;
        mask-size: 60%;
        mask-position: center;
    }

    button { // begin a cancel button
        @include button("tertiary", $button-bordered: true);
        justify-content: center;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}


}