app-assignment-select-assignated {

    .assignment-select-assignated {

        .assignment-select-assignated__content {

            .assignment-select-assignated__primary-content {
                display: flex;
                align-items: center;
                gap: 1.5rem;

                .assignment-select-assignated__radio-container {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    .assignment-select-assignated__radio-button {
                        color: $accent-c;
                        font-size: 1.4rem;
                        font-weight: 600;
                        border-bottom: 1px solid $accent-c;
                        cursor: pointer;
                        transition: color 100ms ease-in-out,
                                    border-bottom 100ms ease-in-out;

                        &.assignment-select-assignated__radio-button--selected {
                            color: rgba($secondary-c, 0.6);
                            border-bottom: 1px solid transparent;
                            pointer-events: none;
                        }

                        &:has(:focus-visible) {
                            @include a11y-focus-visible;
                        }

                        input[type="radio"] {
                            @include visually-hidden;
                        }

                        span {
                            display: inline-block;
                            transform: translateY(0.1em);
                        }
                    }
                }
            }

            .assignment-select-assignated__secondary-content {
                @include transparent-to-white-border;
                border-radius: 16px;

                .assignment-select-assignated__items-to-select-container,
                .assignment-select-assignated__selected-item-container {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    padding: 1rem;

                    .assignment-select-assignated__item-to-select,
                    .assignment-select-assignated__selected-item,
                    .assignment-select-assignated__redo-checkbox {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                        padding: 0.5rem 1rem;
                        cursor: pointer;

                        &:has(:focus-visible) {
                            @include a11y-focus-visible;
                        }

                        span {
                            transform: translateY(0.1em);
                        }

                        input[type="checkbox"] {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            appearance: none;
                            margin: unset;
                            cursor: inherit;
                        }
                    }

                    .assignment-select-assignated__item-to-select,
                    .assignment-select-assignated__selected-item {
                        background-color: rgba(255, 255, 255, 1);
                        border-radius: 16px;
                        box-shadow: 0px 4px 8px 0px rgba($secondary-c, 0.08);
                        font-weight: 700;

                        input[type="checkbox"] {
                            width: 2rem;
                            height: auto;
                            aspect-ratio: 1;
                            background-color: transparent;

                            &::after {
                                content: '';
                                width: 100%;
                                height: 100%;
                                background-color: $secondary-c;
                                mask-size: contain;
                                mask-repeat: no-repeat;
                            }
                        }
                    }
                }

                .assignment-select-assignated__items-to-select-container {
                    background-color: rgba($secondary-c, 0.15);
                    border-radius: inherit;
                    transition: border-bottom-right-radius 100ms ease-in-out,
                                border-bottom-left-radius 100ms ease-in-out;

                    .assignment-select-assignated__item-to-select {
                        transition: filter 100ms ease-in-out;

                        input[type="checkbox"] {

                            &::after {
                                mask-image: icon('add_700.svg');
                            }
                        }

                        &.assignment-select-assignated__item-to-select--selected {
                            filter: contrast(50%) opacity(50%);
                            pointer-events: none;
                        }
                    }
                }

                .assignment-select-assignated__selected-item-container {
                    background-color: $off-white;
                    border-bottom-right-radius: inherit;
                    border-bottom-left-radius: inherit;

                    .assignment-select-assignated__selected-item {

                        input[type="checkbox"] {

                            &::after {
                                mask-image: icon('close_700.svg');
                            }
                        }
                    }

                    .assignment-select-assignated__redo-checkbox {
                        margin-left: auto;
                        background-color: $background-grey;
                        border-radius: 6px;
                        font-weight: 500;

                        input[type="checkbox"] {
                            width: 1.8rem;
                            height: auto;
                            aspect-ratio: 1;
                            background-color: $secondary-c;

                            &::after {
                                content: '';
                                width: 80%;
                                height: 80%;
                                background-color: inherit;
                                mask-image: icon('check.svg');
                                mask-size: contain;
                                mask-repeat: no-repeat;
                            }

                            &:checked {

                                &::after {
                                    background-color: $background-grey;
                                }
                            }
                        }
                    }
                }

                &.assignment-select-assignated__secondary-content--open {

                    .assignment-select-assignated__items-to-select-container {
                        border-bottom-right-radius: unset;
                        border-bottom-left-radius: unset;
                    }
                }
            }

            .assignment-select-assignated__comment {
                padding: 1rem;
                background-color: rgba(255, 255, 255, 1);
                border-radius: 8px;

                &::placeholder {
                    color: rgba($secondary-c, 0.6);
                    font-weight: 500;
                }
            }
        }
    }
}