.medias, .media-wrapper { // parent des media-wrapper ci-dessous  .bem-multimedia__media
    overflow: auto !important;
}

// MEDIA/IMAGE LEGENDE
.medias.bem-multimedia__media {
    // fxLayout
    position: relative;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @include media-breakpoint-up('sm') {
        flex-direction: row;
        @include g-2;
    }

    .media-wrapper {
        max-width: min(100%, 100rem) !important;


        &:not(.pdf-download) {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            @include r-2;
        }

        &:not(.pdf-download):not(.audio) {
            @include tertiary-button-base;
        }

        &.text {
            max-height: calc(100vh - 30rem);
            @include py-2;
            @include px-3;
            font-size: #{set-fluid-scale(16px, 20px)};
        }

        &.audio {
            @include audio-player;
        }

        &.image,
        &.audio {
            width: 80vh;
            height: auto;
            max-height: 100%;
        }

        &.image {
            display: block;
            @include p-0;

            #imgToZoom, & > div, [id="#imgToZoom*"], {
                display: block;
                height: 100%;
                // div.action-buttons { img {} } // div inutile
                img {
                    display: block;
                    object-fit: contain;
                    max-height: 50vh; // pas convaincu
                    margin: 0 auto;

                    @media screen and (max-height: 600px) {
                        max-height: 30vh; // pas convaincu
                    }
                }
            }
        }

        &.pdf-download {
            height: 100%;
        }
    }
}