@use '@angular/material' as mat;

%stepper__button {
    height: 33px;
    width: 44px;

    color: $stepper__button--color; // #E62716;

    /* Blanc */
    background-color: $stepper__button--background-color;
    /* Noir 10% */
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* ombre_bouton */
    box-shadow: #{$stepper__button--box-shadow};
    border-radius: 5px;
}

%stepper__button__number {
    display: flex;
    align-items: center;
    text-align: center;
}

%stepper__button--active {
    color: $stepper__button-active--color; // var(--white);
    /* bleu_tralalere_accessible */
    background: var(--color-lessons-header);
    /* Noir 10% */
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* ombre_interne_actif */
    box-shadow: #{$stepper__button-active--box-shadow}; // inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

%stepper__button--hover {
    color: $stepper__button-hover--color; // var(--white);
    /* bleu_tralalere_accessible */
    background: $stepper__button-hover--background-color;
    /* Noir 10% */
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* ombre_interne_actif */
    box-shadow: #{$stepper__button-hover--box-shadow};
    border-radius: 5px;
}


app-generic-stepper {
    padding-bottom: 10px;

    > .content {
        align-items: center !important;
        padding: 0 var(--padding-lateral-viewport);
        min-height: 65px;
        background: var(--color-lessons-header);

        .steps {
            justify-content: center !important; // inline style
            padding: 16px;
            align-items: center !important;
            max-height: 70px;
        }

        .step {
            margin: 0 2px !important; // inline style
            @extend %stepper__button;

            &.lesson-mode {
                cursor: default; // if we want to lock step further than next one visualy change here by not-allowed
            }

            .number {
                @extend %stepper__button__number;
            }

            .mat-icon {
                display: none;
            }

            &:hover {
                @extend %stepper__button--hover;
            }

            &.active {
                @extend %stepper__button--active;
            }

            &.current {
                color: $stepper__button-current--color; // $accent-c;
                background-color: $stepper__button-current--background-color; // $accent-c;
                box-shadow: #{$stepper__button-current--box-shadow};
                cursor: default;
            }

            &.next-allowed, &.active {
                cursor: default;
            }
        }

        [toolbar-before-stepper], [toolbar-after-stepper] {
            flex: none;
            display: flex;
            gap: 12px;

            button {
                padding: $stepper__button--padding;
                @extend %button--square-icon;
            }
        }

        [toolbar-before-stepper] {
            margin-right: auto;
        }

        [toolbar-after-stepper] {
            margin-left: auto;
            > [toolbar-after-stepper] {
                margin-left: inherit;
                padding-left: 12px;
            }
        }

        mat-select {
            .mat-select-value-text {
                white-space: pre-wrap;
            }
        }

        .mat-form-field {
            .mat-form-field-wrapper {
                padding: 0 12px;
            }

            .mat-form-field-flex {
                padding: 0;
                min-width: auto !important;
            }

            .mat-form-field-infix {
                padding: 0;
                border: 0;
            }

            .mat-select {
                background: rgba(255, 255, 255, 0.06);
                border: #{$stepper__mat-select--border};
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                padding: 5px;
                border-radius: 5px;
                overflow: hidden;
            }

            .mat-select span {
                color: $stepper__mat-select_span--color;
                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
            }

            .mat-select-arrow {
                background-color: $stepper__mat-select_span--color;
                margin-top: auto;
            }
        }

        .mat-form-field-underline {
            display: none;
        }

        button.previous, button.next {
            display: flex;
            justify-content: center !important;
            align-content: center !important;
            align-items: center !important;
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
        }

        button.mat-raised-button {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25) !important;
        }

        .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
            background-color: transparent;
        }

    }
}


.mat-select-panel.generic_stepper_mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-select-panel.generic_stepper_mat-select-panel .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #{$stepper__mat-select-panel__span--color} !important;
}

.mat-select-panel.generic_stepper_mat-select-panel .mat-option {
    background: #{$stepper__mat-select-panel--background};
    border: #{$stepper__mat-select-panel--border};
    box-shadow: #{$stepper__mat-select-panel--box-shadow};
    backdrop-filter: blur(10px);
    color: #{$stepper__mat-select-panel--color};
}

.mat-option:hover:not(.mat-option-disabled) {
    background: #{$stepper__mat-select-panel__generic_stepper_mat-select-panel__hover--background-color};
}

.mat-select-panel.generic_stepper_mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-select-panel.generic_stepper_mat-select-panel .mat-option.mat-selected:not(.mat-option-disabled) {
    background-color: #{$stepper__mat-select-panel__generic_stepper_mat-select-panel--background-color} !important;
}

.mat-select-panel.generic_stepper_mat-select-panel {
    background-color: #{$stepper__mat-select-panel__generic_stepper_mat-select-panel--background-color} !important;

    opacity: 0.96 !important;

    &::-webkit-scrollbar-thumb {
        border: 1px solid transparent !important;
        background: #FFFFFF !important;
        border-radius: 24px;
        box-shadow: inset 0 0 0 24px rgb(255 255 255) !important;
    }
}

app-generic-stepper .mat-form-field-appearance-fill .mat-form-field-flex {
    background: none;
    min-width: 40vw;

}

// Default Old
/*
EX_app-generic-stepper .steps {
    //width: 100%;
    //min-width: 100%;
    //height: 100%;
    //min-height: 100%;
    //
    //.ps__rail-y {
    //    opacity: 0.6;
    //}
    //
    //.ps__rail-x, .ps__rail-y {
    //    z-index: 1;
    //}
    //
    //.step {
    //    position: relative;
    //
    //    .number {
    //        width: 28px;
    //        height: 28px;
    //        background-color: #ffffff;
    //        border-radius: 50%;
    //        text-align: center;
    //        color: #979797;
    //        border: 1px solid #979797;
    //        cursor: pointer;
    //
    //        &.flag {
    //            color: gray;
    //        }
    //    }
    //
    //    &.current {
    //        .number {
    //            border: 1px solid #008A9C;
    //            color: #008A9C;
    //        }
    //    }
    //
    //    &.active {
    //        .number {
    //            border: 1px solid #008A9C;
    //            background-color: #008A9C;
    //            color: #ffffff;
    //        }
    //
    //        .flag {
    //            color: #fff !important;
    //        }
    //    }
    //
    //    &.last {
    //        margin-right: 0 !important;
    //    }
    //
    //    .arrow {
    //        color: #008A9C;
    //        position: absolute;
    //
    //        &.top {
    //            transform: translate(50%, 0%) rotate(-90deg) scale(0.5);
    //            right: 50%;
    //            top: 0;
    //        }
    //
    //        &.bottom {
    //            transform: translate(50%, 50%) rotate(90deg) scale(0.5);
    //            right: 50%;
    //            bottom: 0;
    //        }
    //
    //        &.left {
    //            transform: translate(0%, -50%) rotate(180deg) scale(0.5);
    //            top: 50%;
    //            left: 0;
    //        }
    //
    //        &.right {
    //            transform: translate(0%, -50%) scale(0.5);
    //            top: 50%;
    //            right: 0;
    //
    //        }
    //
    //        ::ng-deep path {
    //            fill: #008A9C;
    //        }
    //    }
    //}
    //
    //&.layout-column.layout-align-start {
    //    padding-top: 25px;
    //}
    //
    //&.layout-row.layout-align-start {
    //    padding-left: 25px;
    //}

}
*/

.fuse-app-lessons-header.no-title {
    min-height: inherit !important;
}



/**
 hide disabled effect on the buttons of stepper in lessons
 */
.mat-mini-fab.mat-button-disabled.mat-button-disabled.fuse-app-lessons__button-fullscreen,
.mat-raised-button.mat-button-disabled.mat-button-disabled.fuse-app-lessons__button-close,
.mat-mini-fab.mat-button-disabled.mat-button-disabled.fuse-app-lessons__button-tools,
app-generic-stepper .mat-button-disabled.mat-button-disabled {
    background-color: $stepper__button-disabled--background-color !important;
    color: $stepper__button-disabled--color !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}