app-progress-multi-zone {
    // flex: none !important; // content.component.scss de fuse par du principe qu'il n'y a qu'un composant dans la page
    padding: 10px var(--padding-lateral-viewport);

    .bem-progress-multi-zone__wrapper {
        display: flex;
        height: 20px;
        background-color: var(--progressionbackground, #E5EBF1);
        border: 2px solid var(--progressionborder, #CEE1EC);
        @include r-2;
        box-shadow: inset 0px 2px 0px 0px var(--progressionborder, #CEE1EC);

        .bem-progress-multi-zone__answer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0;
            transition: width 400ms ease-in-out;

            &::after {
                content: "";
                width: calc(100% - 4px);
                height: calc(100% - 4px);
                border-radius: inherit;
            }

            &.bem-progress-multi-zone__first-answer {
                @include rl-2;
            }

            &.bem-progress-multi-zone__last-answer {
                @include rr-2;
            }

            &.bem-progress-multi-zone__correct-answer {
                background-color: var(--progressiongood, #009E94);

                &::after {
                    border-top: 2px solid rgba(255, 255, 255, 0.5);
                }

                + .bem-progress-multi-zone__correct-answer::after {
                    transform: translateX(-2px);
                    width: 100%;
                }
            }

            &.bem-progress-multi-zone__wrong-answer {
                background-color: var(--progressionbad, #000A10);

                &::after {
                    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
                }

                + .bem-progress-multi-zone__wrong-answer::after {
                    transform: translateX(-2px);
                    width: 100%;
                }
            }
        }

        .bem-progress-multi-zone__cursor {
            display: none;
        }

        .bem-progress-multi-zone__limit {
            display: none;
        }
    }
}