bdtool-assets-menu {
    display: contents !important; // Override bdtool Node module inline style

    > div { // Main container
        top: 0;
        bottom: 0;
        left: 4rem !important; // Override bdtool Node module inline style
        width: 50% !important; // Override bdtool Node module inline style
        height: fit-content;
        max-height: 90%;
        margin-block: auto;
        padding: 1rem !important; // Override bdtool Node module inline style

        > ul { // Grid container
            padding: unset; // Override browser default list style
        }
    }
}