mat-card-header {
    flex: none; // dimension du contenu
    display: flex;
    flex-direction: column;
    padding-inline: var(--padding-lateral-viewport);

    .mat-card-header-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .mat-card-title {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin: 0;

        .read-not-in-progress { // Petit message H2
            margin-bottom: 26px;
            margin-top: -26px;
            @include caption-font;
            color: var(--secondarylabel, #B10745);
            padding-left: calc(clamp(10px, 0.76vw + 5.45px, 20px) + 16px);
        }
    }

    &:empty {
        display: none;
    }
}

// TODO Supprime Default supprime cela
mat-card-header {
.mat-card-header-text {
    align-items: inherit; // Supprime default propriété inutile
}
}