app-assignment-select-one-seance {

    .assignment-select-one-seance {

        .assignment-select-one-seance__content {

            .assignment-select-one-seance__title {
                
                cite {
                    font-style: italic;
                    font-weight: 900;
                }
            }

            .assignment-select-one-seance__session-container {
                @include transparent-to-white-border;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                padding: 0.5rem;
                background-color: rgba($secondary-c, 0.15);
                border-radius: 12px;

                .assignment-select-one-seance__session-button {
                    all: unset; // Unset web browser default button style
                    @include white-to-transparent-border;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1rem 1.5rem;
                    color: $accent-c;
                    background-color: rgba(255, 255, 255, 0.75);
                    border-radius: 8px;
                    cursor: pointer;

                    .assignment-select-one-seance__session-label {
                        font-weight: 700;
                    }
                }
            }
        }
    }
}