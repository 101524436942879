bdtool-comic-boxes-listing-actions {

    ul {
        align-items: center;
        gap: 1.5rem;
        padding-block: 1rem 2rem;

        li {
            @include button(
                $type: primary,
                $icon-full: true,
            );
            position: relative;
            width: 3.5rem !important;
            height: auto;
            aspect-ratio: 1;
            padding: 0.2rem;

            &::after {
                content: '';
                position: absolute;
                inset: 0;
                padding: inherit;
                background-color: white;
                mask-origin: content-box;
                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
                pointer-events: none;
            }

            img {
                display: none;
            }

            &:hover {

                &::after {
                    background-color: white;
                }
            }

            &:active {

                &::after {
                    background-color: white;
                }
            }

            &[disabled] {

                &::after {
                    background-color: #989CA4;
                }
            }

            /*&:nth-child(1) {

                &::after {
                    mask-image: icon('add_file_custom_rounded.svg');
                }
            }*/

            &:nth-child(1) {

                &::after {
                    mask-image: icon('task_alt_rounded.svg');
                }
            }

            &:nth-child(2) {

                &::after {
                    mask-image: icon('download_rounded.svg');
                }
            }

            &:nth-child(3) {

                &::after {
                    mask-image: icon('close_rounded.svg');
                }
            }
        }
    }
}