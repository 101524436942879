@mixin audio-player {
    @include primary-button;
    @include g-3;
    @include p-2;
    flex-direction: column;
    align-items: center !important;
    width: auto; // Should be overriden depending on context
    min-width: 30rem;
    max-width: 100rem;
    height: auto;
    aspect-ratio: 16 / 9;
    pointer-events: none; // Disable primary-button pointer-events

    .mat-icon {
        @include my-auto;
        @include pt-3;
        @include px-3;
        flex: none;
        width: 100%;
        max-width: 50rem;
        height: auto;

        svg {
            width: 100%;
            min-width: unset;
            height: 100%;
            min-height: unset;
        }
    }

    > div { // Audio element wrapper (does not exist in every context)
        width: 100%;
    }

    audio {
        display: block;
        width: 100%; // Required for Safari based browser styling
        height: 4rem; // Required for Chrome based browser styling
        border-radius: 12px;
        pointer-events: auto; // Enable audio element pointer-events
    }
}