mat-dialog-container.mat-dialog-container app-bd-data-editor-modal {
    
    .mat-dialog-content {

        .form-content {

            mat-list {
                //flex-grow: 1;
                //border: 3px solid green;
                //overflow: auto;
                height: 300px;
                background-color: #d8e1f1;
                mat-spinner {
                    margin: 0;
                }

                mat-list-item.active {
                    background-color: #b6becc;
                    border-bottom: 1px solid #9298a2;
                }
            }
        }

        mat-spinner {
            margin: 25% auto; // Why 25% ? I don't know but it's precisely in the middle of the modal
        }
    }
}