#app-feedback {
    form {
        display: flex;
        flex-direction: column;
        margin: auto;
        background-color: white;
        padding: 30px;
        height: 92vh;
        width: 70vh;
        overflow: auto;

        img {
            height: 15vh;
            margin: auto;
        }

        textarea {
            height: 6vh;
            margin-bottom: 20px;
        }

        button.mat-raised-button {
            margin: auto;
            height: 5vh;
        }
    }

    app-upload-file-generic {
        height: auto;

        form {
            height: auto;
        }
    }
}

fuse-register {
    /**
    to show info message at bottom of field
     */
    .info-mail {
        position: absolute;
        margin: auto;
        display: block;
        transform: translate(-14px, 18px);
        font-size: 12px;
    }
}

fuse-profile-about {

    #about {

        .about-content {

            .profile-box {

                .mat-card-actions {
                    padding: 8px;

                    button {

                        .mat-button-wrapper {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }
                    }
                }
            }
        }
    }
}