
// --------------------------------------------------
// BASE
// --------------------------------------------------

@import "base/index"; // pour new css activities

// --------------------------------------------------
// COMPONENTS
// --------------------------------------------------

@import "components/logo";

// --------------------------------------------------
// LAYOUT
// --------------------------------------------------

@import "layout/index";

// --------------------------------------------------
// PAGES
// --------------------------------------------------

@import "pages/lessons-tab";
@import "pages/app-compass";
@import "pages/app-compass-resources";
@import "pages/app-compass-resource";
@import "pages/app-diagnostic";

// --------------------------------------------------
// SHARED
// --------------------------------------------------

@import "shared_core/index";
@import "shared_modules/index";

// --------------------------------------------------
// MODULES
// --------------------------------------------------

// Activities
@import "../../@modules/activities/scss/main"; // Style sheets used by both the platform and the app
#lessons {
    @import "layout/activities/app-generic-stepper";
}
@import "layout/activities/app-read-pdf";
@import "layout/activities/app-tool";
@import "pages/fuse-app-lessons-tab";

// Lesson-edition
@import "../../@modules/lesson-edition/scss/main";

// Activity-edition
@import "../../@modules/activity-edition/scss/main";

// Assignation
@import "../../@modules/assignation/scss/main";

// BD Tool
@import "../../@modules/bdtool/scss/main";