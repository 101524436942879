/*
* DOSSIER D’ABSTRACTIONS CSS-LIBRARY
*
*   Le dossier abstracts/ regroupe les outils et helpers Sass utilisés à travers le projet. Toutes les variables globales,
*   les fonctions, les mixins et les placeholders devraient se retrouver dans ce dossier.
*
*   La règle générale concernant ce dossier est qu’il ne devrait pas retourner une seule ligne de CSS
*   s’il était compilé seul. Ce ne sont ici que des helpers Sass.
*
 */

@import 'a11y'; // fusionné
@import "breakpoints"; // fusionné
@import "button"; // fusionné
@import "cards"; // fusionné
@import "chips"; // fusionné
@import "classesGenerator"; // fusionné
@import 'functions'; // fusionné
@import "fxflex"; // fusionné
@import 'grids'; // fusionné
@import 'gridTemplate'; // fusionné
@import "helpers"; // fusionné
@import "layouts"; // fusionné
@import "map-function"; // fusionné
@import "placeholders";
@import "radius";
@import "spacing";
@import "tabs"; // fusionné
@import "../../../activities/scss/variables_activities";

