//  app-home-page
//      div fxflex="" fxlayout="column" fxlayoutalign="start center"
//          div.banner
//          div.title
//          div.content
//          div.content-cards
//
//              div.p-16 fxflex.gt-sm="50" lt-sm="100" gt-sm="50"

app-home-page {

    .home-page-wrapper {
        gap: 30px;
        padding: 30px;

        .resume-title,
        .discover-title {
            align-self: flex-start;
            margin: 0;
            font-weight: 700;
        }

        .container-banner-home-header {
            width: 100%;
            margin: 0;
            border-radius: 7px;

            .banner {
                position: $app-home-page__banner--position;
                inset: 0;
                margin: auto;
                height: $app-home-page__banner--height;
                max-height: unset;
                margin: 0;
                border-radius: inherit;
            }

            .home-header {
                position: $app-home-page__header--position;
                top: unset;
                width: auto;
                height: unset;
                max-height: unset;
                margin: 0;
                padding: $app-home-page__header--padding;
                color: unset;
                background-image: $app-home-page__header--background-image;
                border-radius: inherit;

                .title {
                    color: $app-home-page__card-header--color;

                    & + .content {
                        margin-top: 15px;
                    }
                }

                .content {

                    p {
                        margin: 0;
                        color: $app-home-page__card-header--color;
                        font-size: $default-font-size;
                        line-height: 1.2;

                        & + p {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }

        .content-cards {
            width: 100%;

            &.data-cards {
                margin-top: 10px;
            }
        }
    }
}

app-home-page .content-cards .mat-card {
    height: 100%;
    min-height: 250px;
}

app-home-page .content-cards .mat-card .mat-card-header {
    background-color: $primary-c;
}

app-home-page .content-cards .mat-card .mat-card-header .mat-card-title-text,
app-home-page .content-cards .mat-card .mat-card-header .mat-card-title {
    color: $app-home-page__card-header--color;
}

app-home-page .content-cards .mat-card .wrapper mat-card-content.mat-card-content {
    color: $default-font-color;
    font-size: $default-font-size;
    font-weight: $default-font-weight;
    line-height: $default-line-height;
}

app-home-page .content-cards > div .mat-card mat-card-actions button {
    @extend %button--accent;
}