fuse-app-lessons-tab {
    // height: 100%;
    &.page-layout.simple {
        flex: 1 1 1e-09px;
        box-sizing: border-box;
        padding-right: 0;
        padding-left: 30px;
        > div {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: $fuse-app-lessons-tab-overflow;// auto; // commenter pour scroll de la page entière
        }
    }
    .content {
        height: 100%;
        @include grid($sm-col: 2, $lg-col: 2, $xl-col: 3, $gap: 22, $row-height-auto: true);
        padding-bottom: 20px;
        padding-right: 20px;
    }

    mat-spinner {
        margin-top: 15%;
    }
}