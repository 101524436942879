@use 'node_modules/@angular/material/index' as mat;

app-consignes {

    .container {
        // Layout
        display: flex; // centre l'image
        width: 100%;
        align-items: center;
        @include px-2; // padding horizontal
        @include g-3; // gap
        // Style
        @include r-2;
        @include tertiary-button;
        min-height: 60px;
        color: var(--tertiarylabel, #006AA6); // overide button color

        > div {
            flex: 1;
            display: flex;
            align-items: center;
        }

        &.consignes__image {
            position: relative;
            overflow: hidden;
            gap: unset;
            padding: unset;
            cursor: unset;

            .consignes__content {
                flex: 1;
                display: flex;
                flex-direction: row; // Set the main axis to row
                align-items: stretch; // Stretch the image child to fill its parent height
                aspect-ratio: 16/9;
                background-color: var(--white, #FFF);

                img {
                    width: 100%; // The image element fills the entire width of its parent
                    height: auto; // Auto adjust the height of the image element depending on the original ratio of the image itself
                    max-height: 100%; // Set the maximum height of the image element to prevent it from overflowing its parent
                    object-fit: contain; // Maintain the original ratio of the image itself even if the image element doesn't
                }
            }

            .consignes__actions {
                display: contents;

                .consignes__expand-icon {
                    @include expand-button;
                }
            }
        }

        mat-icon.mat-icon {
            @include ml-auto;
            color: var(--tertiaryicon, #B10745);
        }

        //.tts.wording.is-instruction {
        .bem-consignes__content { // remplace les divs parents inutiles
            flex: 1;
            display: flex;
            flex-direction: row;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;

            mat-card-header & { // = mat-card-header app-consignes .bem-consignes__content
                @include bold-font;
            }

            mat-card-content & { // = mat-card-content app-consignes .bem-consignes__content
                // legende
                @include body-font;
            }

            .action-buttons { // contient l'icon de fullscreen
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    .consignes__audio-player {
        @include audio-player;
        margin-inline: auto;
    }
}


.mat-card-content > app-consignes { // .mat-card-content > app-consignes ( " > " car page summary inclue dans des carte )
    width: 100%;

    @include media-breakpoint-up('sm'){
        max-width: var(--max-small-device);
    }
}